import { type MetaFunction } from '@remix-run/node';

import styles from '../routes-styles/not-found.module.css';

export const meta: MetaFunction = () => {
  return [
    { title: `Page Not found :( - Dodgeball Hub` },
    { name: 'description', content: 'We could not find the page you are looking for.' },
  ];
};

export default function EventsMap() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>
          4<span className={styles.icon}>0</span>4
        </h1>
        <p>Oops! You dodged the page!</p>

        <a href="/" className={styles.homeLink}>
          Return to Homepage
        </a>
      </div>
    </div>
  );
}
